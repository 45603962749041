body {
  margin: 0;
  font-family: "ubuntuR";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ubuntuB";
  src: url("./utils/fonts/Ubuntu-Bold.woff2") format("woff2"),
    url("./utils/fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ubuntuL";
  src: url("./utils/fonts/Ubuntu-Light.woff2") format("woff2"),
    url("./utils/fonts/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ubuntuM";
  src: url("./utils/fonts/Ubuntu-Medium.woff2") format("woff2"),
    url("./utils/fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ubuntuR";
  src: url("./utils/fonts/Ubuntu-Regular.woff2") format("woff2"),
    url("./utils/fonts/Ubuntu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
p,
ul {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}
